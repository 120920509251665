import React from "react";
import {Helmet} from "react-helmet";

class MyHeadManagement extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.props.title} | V.I.D. Policy</title>
                    <meta name="description" content={this.props.description} />
                    <meta charSet="utf-8"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                </Helmet>
                </>
        );
    }
}

export default MyHeadManagement;
