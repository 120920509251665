import React from "react";
import Navbar from "./navbar";

class MyHeader extends React.Component {

    render() {
        return (
            <div>
                <header aria-label="V.I.D. Header.">
                    <Navbar />
                </header>
            </div>
        );
    }

}

export default MyHeader;
