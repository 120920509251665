import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";
import React from "react";
import MyHeader from "./header/header";
import MyHome from "./home/Home";
import TFPrivacyPolicy from "./policies/TechFreedomPrivacyPolicy";
import MyFooter from "./footer/footer";

class MyApp extends React.Component {

  render() {
    return (
        <Router>
            <MyHeader />
            <main id="main-content">
              <Routes>
                <Route exact path="/" element={<MyHome />} />
                <Route path="/privacy-policy-tech-freedom" element={<TFPrivacyPolicy />} />
              </Routes>
            </main>
            <MyFooter />
        </Router>
    );
  }
}

export default MyApp;
