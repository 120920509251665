import React from "react";

class MyFooter extends React.Component {

    render() {
        return (
            <div>
                <footer aria-label="V.I.D. Footer.">
                    <h2>Our social media platforms</h2>
                    <ul type="none">
                        <li><a href="https://www.facebook.com/VI-Developers-101955688901442" target="_blank" rel="noreferrer">Facebook page</a></li>
                        <li><a href="https://twitter.com/developers_v" target="_blank" rel="noreferrer">Twitter profile</a></li>
                        <li><a href="https://youtube.com/videvelopers" target="_blank" rel="noreferrer">YouTube channel</a></li>
                    </ul>
                    <p>&copy; Copyright 2019 - {new Date().getFullYear()} V.I. Developers. All rights reserved.</p>
                </footer>
            </div>
        );
    }
}

export default MyFooter;
