import React from "react";
import MyHeadManagement from "../other/head_management";

class MyHome extends React.Component {

    render() {
        return (
            <div>
                <MyHeadManagement title="Home" description="Official website of V.I.D. Policy." />
                <h1>Home</h1>
                <p>This is an official website for policies of V.I. Developers.</p>
            </div>
        );
    }
}

export default MyHome;
